body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.root {
  flexGrow: 1;
  width: 100%;
}

.selectContainer {
  padding-top: 18px;
  padding-bottom: 18px;
}

.select {
  line-height: 41px;
}

.button {
  height: 48px;
}

.component {

}

.divider {
  margin: 20px !important;
}

.view {
  padding: 25px;
}


.form {
  padding: 0 20px 20px 20px;
}

.table {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  background-color: #1769aa;
  color: white;
}

.tableHead {
}

.tablePaper {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.sortLink:hover {
  color: whitesmoke !important;
}

.tableBody {
  background-color: white;
}

.tabBar {
  background-color: #1769aa !important;
  color: white !important;
}

.tab {
  color: whitesmoke !important;
}

.marked {
  box-shadow:inset 7px 0px 0px #aa1768;
}
