.App {
  text-align: center;
}

.root {
  flexGrow: 1;
  width: 100%;
}

.selectContainer {
  padding-top: 18px;
  padding-bottom: 18px;
}

.select {
  line-height: 41px;
}

.button {
  height: 48px;
}

.component {

}

.divider {
  margin: 20px !important;
}

.view {
  padding: 25px;
}


.form {
  padding: 0 20px 20px 20px;
}

.table {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  background-color: #1769aa;
  color: white;
}

.tableHead {
}

.tablePaper {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.sortLink:hover {
  color: whitesmoke !important;
}

.tableBody {
  background-color: white;
}

.tabBar {
  background-color: #1769aa !important;
  color: white !important;
}

.tab {
  color: whitesmoke !important;
}

.marked {
  -webkit-box-shadow:inset 7px 0px 0px #aa1768;
  -moz-box-shadow:inset 7px 0px 0px #aa1768;
  box-shadow:inset 7px 0px 0px #aa1768;
}